import { IMention } from '@/types';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth(); // Months are zero-indexed (0 = January, 1 = February, etc.)
  const year = date.getFullYear();

  // Array of month names
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Function to get the ordinal suffix for a day
  function getOrdinalSuffix(day: number) {
    if (day > 3 && day < 21) return 'th'; // Covers 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  const formattedDay = `${day}${getOrdinalSuffix(day)}`;
  const formattedMonth = monthNames[month];
  const formattedDate = `${formattedDay} ${formattedMonth}, ${year}`;

  return formattedDate;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function groupData<T>(data: T, subKey: string): Record<string, string[]> {
  return Array.isArray(data)
    ? data.reduce(
        (acc, item) => {
          if (!acc[item.name]) {
            acc[item.name] = [];
          }
          if (item[subKey]?.name) {
            acc[item.name].push(item[subKey].name);
          }
          return acc;
        },
        {} as Record<string, string[]>
      )
    : data;
}

export function convertToBase64(file: File) {
  let result = '';
  const reader = new FileReader();
  reader.onloadend = () => {
    const base64String = reader.result as string;
    result = base64String;
  };
  reader.readAsDataURL(file);

  return result;
}

export function generateCountArray(n: number): number[] {
  return Array.from({ length: n }, (_, i) => i + 1);
}

export const checkMediaTypeByExtension = (url: string): 'image' | 'video' | null => {
  // Define the common image and video file extensions
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm'];

  // Extract the file extension from the URL
  const extensionMatch = url.split('.').pop()?.toLowerCase();

  if (extensionMatch) {
    if (imageExtensions.includes(extensionMatch)) {
      return 'image';
    } else if (videoExtensions.includes(extensionMatch)) {
      return 'video';
    }
  }

  return null; // Return null if the extension doesn't match any image or video types
};

export const parseMentions = (text: string) => {
  const mentionRegex = /@\[(.+?)\]\(id:(\d+)\)/g;
  const parts: (string | IMention)[] = [];
  let lastIndex = 0;
  let match;

  // Match all mentions in the string
  while ((match = mentionRegex.exec(text)) !== null) {
    const [fullMatch, name, id] = match;
    const startIndex = match.index;

    // Add text before the mention
    if (startIndex > lastIndex) {
      parts.push(text.slice(lastIndex, startIndex));
    }

    // Add the mention as an object
    parts.push({ name, id });
    lastIndex = mentionRegex.lastIndex;
  }

  // Add remaining text after the last mention
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

export async function convertToImageBase64(imageUrl: string): Promise<string> {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const formatPriceInNaira = (
  rawAmount: string | number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
): string => {
  const amount = +rawAmount;
  if (amount === undefined) return '';

  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(amount);
};

export function generateTimeOptions() {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const ampm = hour < 12 ? 'AM' : 'PM';
      const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
      const timeString = `${hour12.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}${ampm}`;
      options.push(timeString);
    }
  }
  return options;
}

export function transformStartAndEnd(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Format start time as "hh:mmAM/PM"
  const hours = (start.getHours() % 12 || 12).toString().padStart(2, '0'); // Convert to 12-hour format with two digits
  const minutes = start.getMinutes().toString().padStart(2, '0');
  const ampm = start.getHours() >= 12 ? 'PM' : 'AM';
  const startTime = `${hours}:${minutes}${ampm}`;

  // Calculate duration in hh:mm format
  const durationMs = end.getTime() - start.getTime();
  const durationHours = Math.floor(durationMs / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0');
  const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, '0');
  const duration = `${durationHours}:${durationMinutes}`;

  return { startTime, duration };
}
